/* eslint-disable no-restricted-globals */
import React, { ReactNode, HTMLInputTypeAttribute } from 'react'

import styled from 'styled-components'

import { GoPlay as FiPlay } from 'react-icons/go'

var thumbnailYoutubeVimeo = require('thumbnail-youtube-vimeo')

import mediaqueries from '../../styles/media'

import Modal from '../Modal'

export const VideoMod: React.FC<{
  [prop: string]: any
  content: ReactNode | HTMLInputTypeAttribute
  link: string
  originalLink: string
}> = ({ link, content, children, originalLink }) => {
  // @ts-ignore
  const [modalVisibility, setmodalVisibility] = React.useState(false)
  const [contentLink, setContentLink] = React.useState()

  const setModalVisibilityL = (link) => {
    setContentLink(link)
    setmodalVisibility(true)
  }

  return (
    <ActionBar>
      <ActionNavList id='action-list' className='row'>
        <VideoItem
          link={link}
          toggleModal={(link) => setModalVisibilityL(link)}
          content={content}
          originalLink={originalLink}
          children={children}
        />
      </ActionNavList>
      <Modal
        show={modalVisibility}
        link={contentLink}
        setShow={(show) => setmodalVisibility(show)}
        className='video-modal-style'
      ></Modal>
    </ActionBar>
  )
}

export default VideoMod

var ActionBar = styled.div`
  height: 100%;
`

const ActionNavList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: inherit;
  list-style: none;
  justify-content: center;
  text-align: center;

  * {
    text-align: center;
  }
`

const NavItem = styled.li`
  height: 100%;
`

const ActionButton = styled.button`
  font-family: ${(p) => p.theme.fonts.header};
  font-weight: ${(p) => p.theme.fonts.weights.heavy};
  font-size: ${(p) => p.theme.fontSizes[5]}px;
  color: ${(p) => p.theme.colors.secondary};
  -webkit-appearance: button;
  display: flex;
  height: 100%;
  padding: 0;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-around;

  & h2,
  h1,
  h3 {
    text-transform: none;
    cursor: pointer;
    color: ${(p) => p.theme.colors.secondary};
    text-align: center;
    line-height: 1.5;
    box-sizing: inherit;
    padding: 0;
    font-size: 2rem;
    z-index: 5;

    width: 100%;
    max-width: 680px;
  }
`
const VideoContainer = styled.div`
  position: relative;
  img {
    position: relative;
    z-index: 10;
  }
  svg.play-icon {
    z-index: 20;
    font-size: 48px;
    color: ${(p) => p.theme.colors.branded.syncRoseHue[0]};
    position: absolute;
    cursor: pointer;
    top: 35%;
    left: 40%;
  }
`

const VideoItem: React.FC<{
  link: string
  originalLink: string
  toggleModal: any
  content: ReactNode | HTMLInputTypeAttribute
  [x: string]: any
}> = ({ link, toggleModal, content: buttonText, originalLink, children }) => {
  const [thumb, setThumb] = React.useState('')
  React.useEffect(() => {
    thumbnailYoutubeVimeo(originalLink)
      .then((res) => {
        setThumb(res)
      })
      .catch(console.log)
    return () => {setThumb(null)}
  }, [originalLink])
  return (
    <NavItem className=''>
      {thumb ? (
        <ActionButton onClick={() => toggleModal(link)} className='col-12'>
          {children}
          <VideoContainer className='video-container'>
            <img src={thumb} alt={buttonText as string} className='col-12' />
            <FiPlay className='play-icon' />
          </VideoContainer>
        </ActionButton>
      ) : (
        <ActionButton onClick={() => toggleModal(link)} className='col-12'>
          {children}
          <div className='video-container'>
            <div className='col-12' />
            <FiPlay className='play-icon' />
          </div>
        </ActionButton>
      )}
    </NavItem>
  )
}
