/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react'
import styled from 'styled-components'
import { useLink } from '@bestyled/contrib-common'
import queryString from 'query-string'

import mediaqueries from '../styles/media'
const Btn = (props) => {
  const { Link } = useLink()

  let { to } = props

  if (typeof window !== 'undefined' && window.location && props.to && props.withParams) {
    const params = queryString.parse(window.location.search)
    let state: string | undefined = params?.s as string
    let specialtyFilter: string | undefined = (params?.specialty as string) || ''
    let surveyType: string | undefined = params?.surveytype as string
    let surveyScore: number | undefined = parseInt(params?.surveyscore as string)
    if (/isi/gi.test(surveyType)) {
      // check the corresponding scoring value
      if (surveyScore > 14) {
        specialtyFilter = 'sleep'
      }
    }
    if (state === 'US' || state?.length !== 2) {
      state = ''
    }
    if (params.couples && /couple/gi.test(params.couples)) {
      specialtyFilter = 'couples'
    } else if (params.specialty) {
      specialtyFilter = params.specialty as string
    }
    const stateURL = `${state ? `/${state}/` : '/'}`
    to = `${to.replace(/\/$/, '')}${stateURL}${specialtyFilter}?${queryString.stringify(params)}`
  }

  return props.href ? <a {...props} /> : <Link {...props} to={to} />
}
const Button = styled(Btn)`
  &:hover p {
    background: ${(p) => p.theme.colors.buttonHover} !important;
    color: ${(p) => p.theme.colors.buttonHoverBg} !important;
  }
  & > * {
    font-family: ${(p) => p.theme.fonts.menu} !important;
    margin: 0 35px !important;
    text-rendering: optimizelegibility;
    word-break: keep-all;
    text-transform: uppercase;
    line-height: 1.5 !important;
    box-sizing: inherit;
    text-transform: ${(p) => p.theme.textTransforms.button};
    letter-spacing: 2px;
    color: ${(p) => p.theme.colors.button} !important;
    background: ${(p) => p.theme.colors.buttonBg} !important;
    font-weight: 600;
  }
  & > p {
    margin: auto 5px !important;
  }
  font-family: ${(p) => p.theme.fonts.menu};
  text-transform: ${(p) => p.theme.textTransforms.button};
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 161px;
  height: 38px;
  border: 1px solid
    ${(p) => (p.theme.colors.buttonBg === 'transparent' ? p.theme.colors.brand : p.theme.colors.buttonBg)};
  color: ${(p) => p.theme.colors.button};
  background: ${(p) => p.theme.colors.buttonBg};
  font-weight: 600;
  border-radius: ${(p) => p.theme.borderRadius.button};
  transition: border-color 0.2s var(--ease-in-out-quad), background 0.2s var(--ease-in-out-quad),
    color 0.2s var(--ease-in-out-quad);
  &:hover {
    background: ${(p) => p.theme.colors.buttonHover};
    color: ${(p) => p.theme.colors.buttonHoverBg};
    border-color: ${(p) => p.theme.colors.buttonHoverBg};
  }
  &[disabled] {
    cursor: not-allowed;
  }
  svg * {
    fill: ${(p) => p.theme.colors.brandBg};
  }
  ${(p) => mediaqueries.tablet`
    position: relative;
    height: 30px;
    width: 100%;
    top: 0;
    left: 0;
    border: 1px solid
    ${(p) => (p.theme.colors.buttonBg === 'transparent' ? p.theme.colors.brand : p.theme.colors.buttonBg)};
    color: ${(p) => p.theme.colors.button};
    background: ${(p) => p.theme.colors.buttonBg};
    font-weight: 600;
    border-radius: ${(p) => p.theme.borderRadius.button};
    transition: border-color 0.2s var(--ease-in-out-quad),
      background 0.2s var(--ease-in-out-quad), color 0.2s var(--ease-in-out-quad);
    &:hover {
      background: ${(p) => p.theme.colors.buttonHover};
      color: ${(p) => p.theme.colors.buttonHoverBg};
      border-color: ${(p) => p.theme.colors.buttonHoverBg};
    }
    &[disabled] {
      cursor: not-allowed;
    }
    svg * {
      fill: ${(p) => p.theme.colors.brandBg};
    }
  `}
`

export default Button
