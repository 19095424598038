/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import styled, { useTheme } from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import { useI18n } from '@bestyled/primitives'

import { useMediaSize } from '@bestyled/contrib-common'

import Section from './Section'
import mediaqueries from '../styles/media'

import NavigationMenuDesktop from './Navigation.Menu.Desktop'
import NavigationHamburger from './Navigation.Hamburger'

export const Navigation: React.FC<{
  location: Location
  active: boolean
  isHomepage?: boolean
  setActive: (boolean) => void
}> = ({ active, setActive, location, isHomepage }) => {
  const [showBackArrow, setShowBackArrow] = useState(false)
  const [showPreviousPath, setShowPreviousPath] = useState(false)
  const [linkBackToHome, setLinkBackToHome] = useState(false)

  const theme = useTheme()
  // @ts-ignore
  const { Logo, LogoMobile, breakpointLabels, colors, CustomerLogo, CustomerLogoUrl, HideSynctalkLogo = false } = theme
  const { size: mediaSize } = useMediaSize()
  const isTablet = mediaSize - 1 < breakpointLabels.tablet_large
  const isMobile = isTablet
  const isCustomerLogoAvailable = CustomerLogo || CustomerLogoUrl

  const fill = colors.card

  const { basePath, basePathI18n } = useI18n()

  const handleEscKeyPress = ({ key }) => {
    if (key === 'Escape') {
      handleOutsideClick()
    }
    if (key === 'g') {
      if (!active && !document.getElementById('CommandLineInput')) {
        handleToggleClick()
      }
    }
  }

  const handleToggleClick = () => {
    setActive(!active)
  }

  const handleOutsideClick = () => {
    if (active) {
      setActive(false)
    }
  }

  const navigateOut = (e, path) => {
    e.preventDefault()
    handleOutsideClick()

    if (path === '#') {
      return
    }

    setTimeout(() => {
      navigate(path)
    }, 250)
  }

  useEffect(() => {
    const prev = localStorage.getItem('previousPath') // set in onRouteUpdate by gatsby

    const isHomepage = location.pathname === basePathI18n || location.pathname === basePath

    const previousPathWasHomePageOrSitePage =
      prev === basePath || (prev && /^\/\w\w(?:-\w\w)?\/$/.test(prev)) || (prev && prev.includes('/page/'))

    const isNotPaginated = !location.pathname.includes('/page/')

    setShowBackArrow(!isHomepage && previousPathWasHomePageOrSitePage && isNotPaginated)

    // for future use

    const urlsThatUseBackButton = ['/scheduling/']

    setLinkBackToHome(urlsThatUseBackButton.some((pathname) => location.pathname.indexOf(pathname) >= 0))
  }, [location.pathname])

  useEffect(() => {
    window.addEventListener('keydown', handleEscKeyPress)

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('keydown', handleEscKeyPress)
      }
    }
  }, [])

  const isSpecialistsPage = location.pathname.includes('/specialists')

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <Section className='container' id='NavHeaderSection'>
        <div>
          <NavContainer className='row'>
            {showBackArrow && (
              <LogoBack onClick={() => window.history.back()} data-a11y='false'>
                <BackChevron />
              </LogoBack>
            )}
            {linkBackToHome && !showPreviousPath && (
              <LogoBack onClick={() => navigate(basePathI18n)} data-a11y='false'>
                <BackChevron fill={fill} />
              </LogoBack>
            )}
            {!isSpecialistsPage && (
              <LogoContainer id='NavLogoContainer' to={basePathI18n} aria-label='Back home' data-a11y='false'>
                {isCustomerLogoAvailable && <CustomerLogoHeader id='customer-logo-header' />}
                {!HideSynctalkLogo && (
                  <div className={isCustomerLogoAvailable && 'logo-border'}>
                    {isMobile ? <LogoMobile fill={fill} /> : <Logo fill={fill} />}
                  </div>
                )}
              </LogoContainer>
            )}
            <NavigationMenuDesktop
              active={isTablet ? active : true}
              navigateOut={navigateOut}
              isHomepage={isHomepage}
            />
            {isTablet && (
              <NavigationHamburger
                active={active}
                onClick={handleToggleClick}
                asCloseButton={false}
                isHomepage={isHomepage}
              />
            )}
          </NavContainer>
        </div>
      </Section>
    </OutsideClickHandler>
  )
}

export default Navigation
const backgroundSet = (p) => {
  const theme = p.theme
  if (!theme.CustomerLogo && theme.CustomerLogoUrl) {
    return `background-image: url(${theme.CustomerLogoUrl});`
  }
  return `background-image: -webkit-image-set(
    url(${theme.CustomerLogoTablet}) 1x,
    url(${theme.CustomerLogo}) 2x,
    url(${theme.CustomerLogoUrl}) 3x
  );
  background-image: image-set(
    url(${theme.CustomerLogoTablet}) 1x,
    url(${theme.CustomerLogo}) 2x,
    url(${theme.CustomerLogoUrl}) 3x
  );`
}
const NavContainer = styled.div`
  transition: ${(p) => p.theme.colorModeTransition};
  position: relative;
  padding: 30px 0 0 4vw;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.card};

  ${mediaqueries.phablet_up`
    padding: 20px 1vw 0;

  `};
  ${mediaqueries.phablet`
    padding: 10px 2vw 0;

  `};
`

const LogoBack = styled.button`
  position: absolute;
  left: -44px;
  top: 107px;

  transition: 0.2s transform var(--ease-out-quad);
  opacity: 0;
  animation: fadein 0.3s linear forwards;

  @keyframes fadein {
    to {
      opacity: 1;
    }
  }

  svg {
    transition: transform 0.25s var(--ease-out-quad);
  }

  &:hover svg,
  &:focus svg {
    transform: translateX(-4px);
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 100%;
    height: 100%;
    border: 2px solid ${(p) => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.desktop_medium`
    top: 57px;
    left: -34px;
  `}

  @media screen and (max-height: 800px) {
    top: 67px;
  }

  ${mediaqueries.tablet`
    display: none;
  `}
`

const LogoContainer = styled(Link)`
  position: relative;
  transition: opacity 0.3s var(--ease-out-quad);
  /* max-width: 50vw; */
  display: flex;
  flex-direction: row;
  overflow-x: visible;
  max-width: 45vw;
  svg {
    max-width: 40vw;
  }
  * {
    overflow-x: visible;
  }

  ${mediaqueries.phablet`
    max-width: 70vw;
    margin-top: -15px
    overflow-x: visible;

    * svg: {
      overflow-x: visible;
      max-width: 60vw;
    }
   
  `}
  ${mediaqueries.tablet`
    margin-top: -15px
    overflow-x: visible;

    * svg: {
      overflow-x: visible;
      max-width: 60vw;
    }
  `}

  &:hover {
    opacity: 0.6;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -42%;
    width: 120%;
    height: 200%;
    border: 2px solid ${(p) => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }
  ${(p) => p.theme.headerLogo}
`

const BackChevron = ({ fill = 'black' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z' fill={fill} />
  </svg>
)

const CustomerLogoHeader = styled.div`
  min-width: 100px;
  width: 30%;
  background-image: url(${(p) => p.theme.CustomerLogoUrl});
  background-size: contain;
  background-repeat: no-repeat;

  margin-right: 10px;
  min-height: 100%;
  overflow: visible;
  ${(p) => backgroundSet(p)}
`
